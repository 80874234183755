
@import "../../bootstrap/variables.less";

@body-bg:					#f8ddbf;

@text-color:				#000;
@link-color:				#00b2d9;
@link-hover-color:			#fff;
@link-hover-decoration:		none;

@font-family-base:			Arial, Helvetica, sans-serif;
@font-size-base:			10px;
@line-height-base:			1;

@font-size-h1:				50px;
@headings-small-color:		#fff;

@thumb-width:				400px;
@thumb-height:				300px;
@thumb-margin-top:			1px;
@thumb-margin-right:		1px;
@thumb-margin-bottom:		1px;
@thumb-margin-left:			1px;
@thumb-border-width:		1px;
@thumb-border:				1px solid #fff;
@thumb-hover-border:		1px dashed #00b2d9;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				#FFFF00;
@popup-text-color:			#fff;
@popup-font-weight:			bold;

@social-links-margin:				5px;

@sites-list-font-size:				20px;
@sites-list-color:					#000;
@sites-list-title-margin-bottom:	5px;
@sites-list-title-size:				18px;
@sites-list-title-weight:			normal;
@sites-list-title-decoration:		none;

@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/modules.less";
@import "common/sites_list.less";
@import "common/popup.less";
@import "common/mozaique_simple.less";

.header {
	background: #000;
	color: #fff;
	margin-bottom: 30px;
	overflow: hidden;
}
h1 {
	font-weight: bold;
	font-size: 20px;
	color: #fff;
	text-align: left;
	float: left;
	margin: 0;
	padding: 0 0 0 5px;
	.title {
		font-size: 25px;
		width: 100%;
		display: inline-block;
	}
}
.intro {
	font-size: 12px;
	font-weight: bold;
	padding: 3px 5%;
	overflow: hidden;
}
#social-links {
	margin: 5px;
	width: 270px;
	overflow: hidden;
	float: right;
	clear: none;
}
#language-switcher {
	position: static;
	float: right;
	margin: 0 10px;
}
.footer {
	font-size: 14px;
	margin: 30px 0 5px;
	a {
		text-decoration: underline;
	}
}
@media(max-width: 1200px) {
	.intro {
		clear: both;
		padding: 5px;
	}
}